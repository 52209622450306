import React from "react";
import Helmet from "react-helmet";
import iosImage from "../../../assets/images/IOS.svg";
import value1 from "../../../assets/images/Picture1.png";
import value2 from "../../../assets/images/Picture2.png";
import value3 from "../../../assets/images/Picture3.png";
import value4 from "../../../assets/images/Picture4.png";
import value5 from "../../../assets/images/Picture5.png";
import value6 from "../../../assets/images/Picture6.png";
import value7 from "../../../assets/images/Picture7.png";
import value8 from "../../../assets/images/Picture8.png";
import slotLogo from "../../../assets/images/slotLogo.svg";
import appImage from "../../../assets/images/Appimage.png";

// import slide from "../../../assets/images/landingBackground.jpg";
import googlePlayImage from "../../../assets/images/googlePlay.svg";
import "../../../assets/vendor/animate.css/animate.min.css";
import "../../../assets/vendor/swiper/swiper-bundle.min.css";
import "../../../assets/vendor/boxicons/css/boxicons.min.css";
import "../../../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../../../assets/vendor/glightbox/css/glightbox.min.css";
import "../../../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../../../style/general/newLanding.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from 'react';


export default function NewLanding() {
  const [question, setQuestion] = useState('');

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleEmailClick = () => {
    const emailAddress = 'cc@slotyourspace.com';
    const subject = 'New Question';
    const body = encodeURIComponent(question);

    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  };


  return (
    <div>


      <header id="header" className="d-flex justify-content-start align-items-center">
        {/* <div className="container d-flex justify-content-start align-items-center"> */}
        <img src={slotLogo} width="99" ></img>

        {/* </div> */}
      </header>

      {/* ======= slides Section ======= */}
      <section className="new" id="hero">
        <div
          id="heroCarousel"
          data-bs-interval={5000}
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {/* <ol className="carousel-indicators" id="hero-carousel-indicators" /> */}
          <div className="carousel-inner" role="listbox">
            {/* Slide 1 */}
            <div
              className="carousel-item active"
            // style={{ backgroundImage: "url(" + slide + ")" }}
            >
              <div className="container content-wrapper d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-section col-12 col-md-6 animate__animated animate__fadeInDown">

                  <h2 className=" welcome-carousel-container animate__animated animate__fadeInDown">

                    Welcome to <span>Slot</span>
                  </h2>
                  <p className="welcome-carousel-container animate__animated animate__fadeInUp">
                    Slot is an application that allows users <br />to reserve spaces, experience & skills
                  </p>
                  <div className="btn-wrapper d-flex flex-column flex-md-row">
                    <a
                      href="https://apps.apple.com/us/app/slot-your-space/id6503728726"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      <img src={iosImage} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.slotyourspace.app&hl=en_AU&pli=1"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      <img src={googlePlayImage} />
                    </a>
                  </div>
                </div>
                {/* Right column: Image */}
                <div className="image-section col-12 col-md-6  justify-content-center align-items-center animate__animated animate__fadeInUp">
                  <img src={appImage} alt="app" className="img-fluid right-image" />
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>





      <footer id="footer">
        <div className="container">
          <a
            className="nav-link scrollto"
            href="privacy-policy"
            style={{ display: "inline-block", color: "#014D2F" }}
          >
            <strong> Privacy Policy{" "}  &nbsp; |  &nbsp;</strong>
          </a>{" "}
          <a
            className="nav-link scrollto"
            href="terms-conditions"
            style={{ display: "inline-block", color: "#014D2F", }}
          >
            <strong>Terms & Conditions</strong>
          </a>
        </div>
      </footer>
      <section id="topbar" className="new d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between flex-column flex-md-row">
          <div className="contact-info d-flex align-items-center justify-content-center">
            <i className="bi bi-envelope-fill" />
            <a href="mailto:CC@slotyourspace.com">CC@slotyourspace.com</a>
            <i className="bi bi-phone-fill phone-icon" /> +968 94045745
          </div>
          <div className="social-links d-flex flex-wrap justify-content-center justify-content-md-end mt-3 mt-md-0">
            <a
              href="https://x.com/slot_yourspace"
              className="twitter"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/slot_yourspace/"
              className="instagram"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>
            </a>
            <a
              href="https://www.threads.net/@slot_yourspace?xmt=AQGzc4ldw-BbJ__VvAqu3YqzXgoBpi2LrjgoT8hvYYH0k4E"
              className="threads"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-threads-fill" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M6.81 9.204c0-.41.197-1.062 1.727-1.062.469 0 .758.034 1.146.121-.124 1.606-.91 1.818-1.674 1.818-.418 0-1.2-.218-1.2-.877Z" />
                <path d="M2.59 16h10.82A2.59 2.59 0 0 0 16 13.41V2.59A2.59 2.59 0 0 0 13.41 0H2.59A2.59 2.59 0 0 0 0 2.59v10.82A2.59 2.59 0 0 0 2.59 16M5.866 5.91c.567-.81 1.315-1.126 2.35-1.126.73 0 1.351.246 1.795.711.443.466.696 1.132.754 1.983q.368.154.678.363c.832.559 1.29 1.395 1.29 2.353 0 2.037-1.67 3.806-4.692 3.806-2.595 0-5.291-1.51-5.291-6.004C2.75 3.526 5.361 2 8.033 2c1.234 0 4.129.182 5.217 3.777l-1.02.264c-.842-2.56-2.607-2.968-4.224-2.968-2.675 0-4.187 1.628-4.187 5.093 0 3.107 1.69 4.757 4.222 4.757 2.083 0 3.636-1.082 3.636-2.667 0-1.079-.906-1.595-.953-1.595-.177.925-.651 2.482-2.733 2.482-1.213 0-2.26-.838-2.26-1.936 0-1.568 1.488-2.136 2.663-2.136.44 0 .97.03 1.247.086 0-.478-.404-1.296-1.426-1.296-.911 0-1.16.288-1.45.624l-.024.027c-.202-.135-.875-.601-.875-.601Z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/slot-your-space/"
              className="linkedin"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@Slot_yourspace"
              className="youtube"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
              </svg>

            </a>

            <a
              href="https://www.tiktok.com/@slot_yourspace?_t=8peVdPkQV5m&_r=1"
              className="tiktok"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16" style={{ padding: "0px", display: 'inline-block' }}>
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css"
        />
        <script
          type="text/javascript"
          src="../../public/js/bootstrap/js/bootstrap.bundle.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/glightbox/js/glightbox.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/isotope-layout/isotope.pkgd.min.js"
        ></script>
        <script
          type="text/javascript"
          src="../../public/js/swiper/swiper-bundle.min.js"
        ></script>
        <script src="./js/main.js" type="text/javascript" />
      </Helmet>
    </div >
  );
}
